
export default {
  name: 'MoleculeSpecsList',
  props: {
    specsList: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
